
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonNumberInput from '@/components/common/CommonNumberInput.vue'
import CommonDateInput from '@/components/common/CommonDateInput.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { isEndingDateBeforeStartingDate, isPercentageRange, isRequired } from '@/utils/validation'
import { convertCommaIntoDotInputWithForm, convertDotIntoCommaInputWithForm } from '@/utils/convertInput'
import { dateDotNotationToDashWithForm, dateDashNotationToDotWithForm } from '@/utils/convertDate'
import { handleError } from '@/utils/handleError'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'

import { useCreateContract, useUpdateContract } from '@/api/contract'
import { useGetOrgUnitsBasic } from '@/api/orgUnit'
import { useGetSuppliersBasic } from '@/api/supplier'
import { useGetPersonsBasic } from '@/api/person'
import { useGetEnumItemsBasic } from '@/api/enumItem'

import { ContractOutput, ContractInput } from '@/api/types/contract'
import { CONTRACTUAL_RELATIONSHIP } from '@/views/types'
import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { isOfType } from '@/utils/types/isOfType'

export default defineComponent({
  name: 'AddEditContractDialog',
  components: {
    CommonAddEditDialog,
    CommonNumberInput,
    CommonDateInput,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    contractToEdit: {
      type: Object as PropType<ContractOutput>,
      default: null,
    },
    contractToDuplicate: {
      type: Object as PropType<ContractOutput | null>,
      default: null,
    },
  },
  setup: (props, { emit, root }) => {
    const personId = ref(root.$route.params.id)

    const isEditMode = computed(() => Boolean(props.contractToEdit))

    const contractToCreate = props.contractToDuplicate !== null ? props.contractToDuplicate : ({} as ContractInput)

    const { exec: getOrgUnitsBasic, data: orgUnits, isLoading: isLoadingOrgUnitsBasic } = useGetOrgUnitsBasic()
    getOrgUnitsBasic()

    const { exec: getPersonsBasic, data: persons, isLoading: isLoadingPersonsBasic } = useGetPersonsBasic()
    getPersonsBasic()

    const { exec: getSuppliersBasic, data: suppliers, isLoading: isLoadingSuppliersBasic } = useGetSuppliersBasic()
    getSuppliersBasic()

    const {
      exec: getEnumItemContractEndCategory,
      data: enumItemsContractEndCategory,
      isLoading: isLoadingEnumItemsContractEndCategory,
    } = useGetEnumItemsBasic()
    getEnumItemContractEndCategory({ params: { enumItemAssignment: 'CONTRACT_END_CATEGORY' } })

    const {
      exec: getEnumItemContractStatus,
      data: enumItemsContractStatus,
      isLoading: isLoadingEnumItemsContractStatus,
    } = useGetEnumItemsBasic()
    getEnumItemContractStatus({ params: { enumItemAssignment: 'CONTRACT_STATUS' } })

    const {
      exec: getEnumItemContractType,
      data: enumItemsContractType,
      isLoading: isLoadingEnumItemsContractType,
    } = useGetEnumItemsBasic()
    getEnumItemContractType({ params: { enumItemAssignment: 'CONTRACT_TYPE' } })

    const DEFAULT_FORM_FIELDS: FormField[] = [
      {
        value: 'organizationalUnit',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnitsBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
      {
        value: 'contractualRelationship',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => CONTRACTUAL_RELATIONSHIP),
        isRequired: true,
        rules: [
          (value: string) =>
            isRequired(value, root.$t('contractData.contracts.form.contractualRelationship') as string),
        ],
        dropdownTextProp: 'name',
      },
      {
        value: 'supplier',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => suppliers.value ?? []),
        isLoading: computed(() => isLoadingSuppliersBasic.value),
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('contractData.contracts.form.supplier') as string)],
        dropdownTextProp: 'name',
      },
      {
        value: 'vendor',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => suppliers.value ?? []),
        isLoading: computed(() => isLoadingSuppliersBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
      {
        value: 'start',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('contractData.contracts.form.start') as string)],
      },
      {
        value: 'end',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: true,
        rules: [
          (value: string) => isRequired(value, root.$t('contractData.contracts.form.end') as string),
          (value: string) => (form.value.start ? isEndingDateBeforeStartingDate(form.value.start, value) : true),
        ],
      },
      {
        value: 'hourlyRate',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: false,
        rules: [],
      },
      {
        value: 'scope',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: true,
        rules: [
          (value: string) => isRequired(value, root.$t('contractData.contracts.form.scope') as string),
          (value: string) => isPercentageRange(value),
        ],
      },
      {
        value: 'dailyWorkingTime',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: true,
        rules: [
          (value: string) => isRequired(value, root.$t('contractData.contracts.form.dailyWorkingTime') as string),
        ],
      },
      {
        value: 'contractStatus',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => enumItemsContractStatus.value ?? []),
        isLoading: computed(() => isLoadingEnumItemsContractStatus.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
      {
        value: 'contractType',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => enumItemsContractType.value ?? []),
        isLoading: computed(() => isLoadingEnumItemsContractType.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
      {
        value: 'contractEndCategory',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => enumItemsContractEndCategory.value ?? []),
        isLoading: computed(() => isLoadingEnumItemsContractEndCategory.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
      {
        value: 'contractEndComment',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
    ]

    const FORM_FIELDS: FormField[] = [
      ...(!personId.value
        ? [
            {
              value: 'person',
              fieldType: FORM_FIELDS_ENUM.DROPDOWN,
              items: computed(() => persons.value ?? []),
              isLoading: computed(() => isLoadingPersonsBasic.value),
              isRequired: true,
              rules: [(value: string) => isRequired(value, root.$t('contractData.contracts.form.person') as string)],
              dropdownTextProp: 'name',
            },
          ]
        : []),
      ...DEFAULT_FORM_FIELDS,
    ]

    const form = ref<ContractInput | ContractOutput>(
      isEditMode.value && props.contractToEdit ? cloneDeep(props.contractToEdit) : cloneDeep(contractToCreate)
    )

    function assignPersonIdToForm(): void {
      form.value.person = Number(personId.value)
    }

    const { createContract, isLoading: isLoadingCreateContract } = useCreateContract()

    async function onAdd(): Promise<void> {
      if (!isOfType<ContractOutput>(form.value, 'id')) {
        try {
          personId.value && assignPersonIdToForm()
          let updatedForm = mapBasicEntityToIdWithForm<ContractInput>(form.value)

          updatedForm = convertCommaIntoDotInputWithForm(FORM_FIELDS, updatedForm)

          updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, updatedForm)

          await createContract(updatedForm)

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateContract, isLoading: isLoadingUpdateContract } = useUpdateContract()

    async function onEdit(): Promise<void> {
      if (isOfType<ContractOutput>(form.value, 'id')) {
        let updatedForm = mapBasicEntityToIdWithForm<ContractOutput>(form.value)

        updatedForm = convertCommaIntoDotInputWithForm(FORM_FIELDS, updatedForm)

        updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, updatedForm)

        try {
          await updateContract(updatedForm.id, updatedForm)

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditContract = computed(() => isLoadingCreateContract.value || isLoadingUpdateContract.value)

    watch(
      () => {
        props.contractToEdit
        props.contractToDuplicate
      },
      () => {
        if (props.contractToEdit) {
          form.value = props.contractToEdit

          form.value = convertDotIntoCommaInputWithForm(FORM_FIELDS, form.value)

          form.value = dateDashNotationToDotWithForm(FORM_FIELDS, form.value)
        }
        if (props.contractToDuplicate) {
          form.value = props.contractToDuplicate

          form.value = dateDashNotationToDotWithForm(FORM_FIELDS, form.value)

          form.value = convertDotIntoCommaInputWithForm(FORM_FIELDS, form.value)
        }
      },
      {
        immediate: true,
      }
    )

    function close(): void {
      emit('added-edited')

      emit('close')
    }

    const highHourlyRate = ref(false)

    watch(
      () => form.value.hourlyRate,
      () => {
        return (highHourlyRate.value = Number(form.value.hourlyRate) >= 300 ? true : false)
      }
    )

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,
        DATA_TYPE,

        FORM_FIELDS,
      },
      state: {
        isEditMode,

        form,

        isLoadingAddEditContract,

        highHourlyRate,
      },
      listeners: {
        onAdd,

        onEdit,
      },
      functions: {
        formRules: {
          isRequired,
        },
      },
    })
  },
})
